<template>
  <base-section
    id="csr-statement"
  >
    <v-container>
      <base-section-heading
        text="At Howellcare Industries, we strive to be a responsible business entity and a community partner that contributes to the society in ways that allow the community to thrive and grow with our business. "
      />
    </v-container>
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCsrStatement',

    data: () => ({
      cards: [
        {
          title: 'Human Rights',
          subtitle: '',
          text: 'The respect for human rights will be held at utmost importance in our company. Respect and integrity are at the core of our company’s values.',
          callout: '01',
        },
        {
          title: 'Health and safety',
          subtitle: '',
          text: 'Howellcare Industries pledges to provide a safe and healthy working environment that meets or exceeds applicable standards for occupational safety and health.',
          callout: '02',
        },
        {
          title: 'Business Ethics and Compliance',
          subtitle: '',
          text: 'Integrity and trust are the foundation of a sustainable business. We, at Howellcare Industries continue to strive to withhold the highest degree of trust of all stakeholders, associates and the consumers. This includes upholding all laws in the places and countries that we do business. ',
          callout: '03',
        },
        {
          title: 'Environmental Stewardship',
          subtitle: '',
          text: 'Our approach to environmental stewardship is making an effort to reduce wastage disposals in our operations and business as well as minimize contaminants that are produced through our products through proper disposal channels.',
          callout: '04',
        },
        {
          title: 'Community Involvement',
          subtitle: '',
          text: 'We recognize that beyond creating job opportunities for the community, it is our responsibility to give back to the community. Through various initiatives, charitable giving and volunteerism, Howellcare Industries seeks to create wellbeing in the society as well as bring joy to the community. We are proud that our associates share our value of community involvement. ',
          callout: '05',
        },
      ],
    }),
  }
</script>
